<template>
  <div>
    <vs-modal
      ref="modal2FAConfig"
      backdropBlur
      :title="$t('modals.configurate2FA.title')"
      removeCloseButton
      dismiss-on="close-button"
    >
      <div class="w-100 text-center">
        <p>
          {{ $t("modals.configurate2FA.description1") }}
        </p>
        <img :src="qrCode" class="float-none mx-auto" />
        <p>
          {{ $t("modals.configurate2FA.description2") }}
        </p>
      </div>
      <div class="w-100 flex">
        <vs-input
          class="mr-3 w-full"
          v-model="otp"
          @keyup.enter="validateOTPAndLogin"
        />
        <vs-button
          @click="validateOTPAndLogin"
          :disabled="!/[0-9]{6}/g.test(otp)"
        >
          {{ $t("modals.configurate2FA.button") }}
        </vs-button>
      </div>
    </vs-modal>

    <vs-modal
      ref="modal2FA"
      backdropBlur
      :title="$t('modals.validate2FA.title')"
      removeCloseButton
      dismiss-on="close-button"
    >
      <div class="w-100 flex">
        <vs-input
          class="mr-3 w-full"
          v-model="otp"
          @keyup.enter="validateOTPAndLogin"
        />
        <vs-button
          @click="validateOTPAndLogin"
          :disabled="!/[0-9]{6}/g.test(otp)"
        >
          {{ $t("modals.validate2FA.button") }}
        </vs-button>
      </div>
    </vs-modal>

    <vs-input
      v-validate="'required|email|min:3'"
      data-vv-validate-on="blur"
      name="email"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      :label-placeholder="$t(`users.fields.email`)"
      v-model="email"
      class="w-full"
      :data-vv-as="$t(`users.fields.email`)"
    />
    <span class="text-danger text-sm mb-8 w-full">
      {{ errors.first("email") }}
    </span>

    <vs-input
      data-vv-validate-on="blur"
      v-validate="'required|min:6'"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      :label-placeholder="$t(`users.fields.password`)"
      v-model="password"
      class="w-full mt-6"
      :data-vv-as="$t(`users.fields.password`)"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">
        {{ $t("loginPage.rememberMe") }}
      </vs-checkbox>
      <router-link to="/forgot-password">{{
        $t("loginPage.forgotPassword")
      }}</router-link>
    </div>
    <!-- <vs-button  type="border" @click="registerUser">Register</vs-button> -->
    <vs-button class="float-right" :disabled="!validateForm" @click="login">
      {{ $t("loginPage.button") }}
    </vs-button>
  </div>
</template>

<script>
import VsModal from "@vuesimple/vs-modal";
import { functions } from "@/firebase/firebaseConfig";

export default {
  components: {
    VsModal,
  },
  data() {
    return {
      qrCode: null,
      email: null,
      password: null,
      checkbox_remember_me: false,
      otp: null,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  methods: {
    validateOTPAndLogin() {
      if (/[0-9]{6}/g.test(this.otp)) {
        this.$vs.loading();
        let validateOTP = functions.httpsCallable("validateOTP");

        validateOTP({
          otp: this.otp,
        }).then(({ data }) => {
          if (data) {
            this.login();
          } else {
            this.otp = null;
            this.$vs.loading.close();
            this.$vs.notify({
              time: 2500,
              title: "Error",
              text: "Invalid OTP code",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
      }
    },
    async checkLogin() {
      // If user is already logged in notify

      if (await this.$store.state.auth.isPenalRetailUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    login() {
      if (this.$refs.modal2FA) this.$refs.modal2FA.close();
      if (this.$refs.modal2FAConfig) this.$refs.modal2FAConfig.close();
      // Loading
      this.$vs.loading();

      setTimeout(() => {
        const payload = {
          checkbox_remember_me: this.checkbox_remember_me,
          userDetails: {
            email: this.email,
            password: this.password,
            otp: this.otp,
          },
          notify: this.$vs.notify,
          closeAnimation: () => {
            this.$vs.loading.close();
            //Reseteamos el otp para poder volver a intentarlo en caso de error
            this.otp = null;
          },
          otpConfig: async () => {
            this.$vs.loading.close();
            //Si no tiene 2FA aún, mandamos a que valide
            let generateOTPSecret =
              functions.httpsCallable("generateOTPSecret");

            const { data } = await generateOTPSecret();
            this.qrCode = data.code;
            this.$refs.modal2FAConfig.open();
          },
          otp: () => {
            this.$vs.loading.close();
            this.otp = null;
            this.$refs.modal2FA.open();
          },
        };

        this.$store.dispatch("auth/loginAttempt", payload);
      });
    },

    // Google login
    loginWithGoogle() {
      this.$store.dispatch("auth/loginWithGoogle", { notify: this.$vs.notify });
    },

    // Facebook login
    loginWithFacebook() {
      this.$store.dispatch("auth/loginWithFacebook", {
        notify: this.$vs.notify,
      });
    },

    // Twitter login
    loginWithTwitter() {
      this.$store.dispatch("auth/loginWithTwitter", {
        notify: this.$vs.notify,
      });
    },

    // Github login
    loginWithGithub() {
      this.$store.dispatch("auth/loginWithGithub", { notify: this.$vs.notify });
    },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push("/pages/register").catch(() => {});
    },
  },
};
</script>

<style lang="scss">
#login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
